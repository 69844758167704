import * as React from "react";
import moment from "moment";

import { EventTimes as IEventTimes } from "../../../models";

import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import { PickerLocalization } from "modules/common/components/pickerLocalization";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import { formatDate, isAllDayEvent } from "utils/dateFormatting";

class EventTimes extends React.Component<ComponentProps, ComponentState> {
    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            showNewEventTime: false,
        };
    }

    public render() {
        const eventStartTimes: string[] = this.props.eventTimes ? Object.keys(this.props.eventTimes) : [];
        const eventStartTime: string | null = eventStartTimes[0] || null;
        const eventEndTime: string | null = this.props.eventTimes && eventStartTime ? this.props.eventTimes[eventStartTime] : null;

        return (
            <div className="event-times">
            {this.getEventTime(eventStartTime, eventEndTime, 0)}
            {eventStartTimes.length > 1 && eventStartTimes.slice(1).map((startTime, index) =>
            <Collapse key={index} appear={true} in={true}>
                <div>
                {this.getEventDayLabel(index + 2, () => this.deleteEventDay(startTime))}
                {this.getEventTime(startTime, this.props.eventTimes![startTime], index + 1)}
                </div>
            </Collapse>
            )}
            {!!eventStartTime &&
            <Button variant="text" color="primary" size="small" startIcon={<AddIcon fontSize="small" />} onClick={this.addEventDay} className="add-event-time">Add Event Day</Button>
            }
        </div>
        );
    }

    private addEventDay = () => {
        const recentStartTime: string = Object.keys(this.props.eventTimes!)[Object.keys(this.props.eventTimes!).length - 1];
        const recentEndTime: string | null = this.props.eventTimes![recentStartTime];

        const newStartTime: string = moment(recentStartTime).add(1, "day").toISOString();
        const newEndTime: string | null = !recentEndTime
        ? null
        : moment(recentEndTime).set("date", moment(newStartTime).get("date")).toISOString();

        this.props.onChange({ ...this.props.eventTimes, [newStartTime]: newEndTime });
    };

    private deleteEventDay = (startTime: string) => {
        let eventTimes: IEventTimes = {};

        Object.keys(this.props.eventTimes!)
        .filter((eventTime) => eventTime !== startTime)
        .map((eventTime) => {
            eventTimes[eventTime] = this.props.eventTimes![eventTime];
            return eventTime;
        });
        
        this.props.onChange(eventTimes);
    };

    private getEventDayLabel = (dayNumber: number, onDelete: () => void) => {
        return (
        <div className="event-time-day-label">
            <div>Day {dayNumber}</div>
            <IconButton size="small" onClick={onDelete}><DeleteIcon fontSize="small" /></IconButton>
        </div>
        );
    };

    /**
     * Used to parse the date to a epoch time in either local time or UTC depending if the event is a all day event or not.
     * @param eventStartTime
     * @param eventEndTime
     * @returns
     */
    private getEventStartTimeInEpoch = (eventStartTime: string, eventEndTime: string | null) => {
        if (isAllDayEvent({ startTime: eventStartTime, endTime: eventEndTime })) {
            const dateTime = new Date(eventStartTime);
            const date = formatDate(dateTime, true);
            return new Date(date).getTime();
        } else {
            return Date.parse(eventStartTime);
        }
    };

    private getEventTime = (eventStartTime: string | null, eventEndTime: string | null, index: number) => {
        const minDate = !!index ? Object.keys(this.props.eventTimes!)[index - 1] : null;

        return (
            <div className="event-time-day-content">
                <div className="date-and-location-field">
                    <Grid container>
                        <Grid item xs={2}>
                            <div className="date-and-location-label">Date</div>
                        </Grid>
                        <Grid item xs={10}>
                            <div>
                                <PickerLocalization>
                                    <DatePicker
                                        format="MMM dd yyyy"
                                        value={eventStartTime ? this.getEventStartTimeInEpoch(eventStartTime, eventEndTime) : null}
                                        minDate={minDate ? Date.parse(moment(minDate).add(1, "day").toISOString()) : undefined}
                                        slotProps={{
                                            textField: { size: "small", placeholder: "", fullWidth: true, inputProps: { readOnly: true } },
                                            openPickerIcon: { color: "primary" },
                                        }}
                                        onChange={(ev) => this.onChangeEventTime(ev, eventStartTime)}
                                        className="date-picker"
                                    />
                              </PickerLocalization>
                          </div>
                      </Grid>
                  </Grid>
              </div>
              <div className="date-and-location-time-field">
                  <Grid container>
                      <Grid item xs={2}>
                          <div className="date-and-location-label">Time</div>
                      </Grid>
                      {!!eventStartTime && eventEndTime && (
                          <>
                              <Grid item xs={4.5}>
                                  <TimePicker
                                      value={eventStartTime ? moment(eventStartTime).toDate() : undefined}
                                      disabled={!eventStartTime || !eventEndTime}
                                      onChange={(ev: Date) => this.onChangeEventStartTime(ev, eventStartTime!)}
                                      slotProps={{ textField: { size: "small", fullWidth: true } }}
                                  />
                              </Grid>
                              <Grid item xs={1} textAlign="center" alignItems="center" justifyContent="center" display="flex">
                                  to
                              </Grid>
                              <Grid item xs={4.5}>
                                  <TimePicker
                                      value={eventEndTime ? moment(eventEndTime).toDate() : undefined}
                                      disabled={!eventStartTime || !eventEndTime}
                                      onChange={(ev: Date) => this.onChangeEventEndTime(ev, eventStartTime!)}
                                      minTime={eventStartTime ? moment(eventStartTime).toDate() : undefined}
                                      skipDisabled={true}
                                      slotProps={{ textField: { size: "small", fullWidth: true } }}
                                  />
                              </Grid>
                              <Grid item xs={2} />
                          </>
                      )}
                      <Grid item xs={10}>
                          <FormControlLabel
                              control={
                                  <Switch
                                      color="primary"
                                      checked={!!eventStartTime && !eventEndTime}
                                      disabled={!eventStartTime}
                                      onChange={(ev, checked) => this.onToggleAllDay(ev, checked, eventStartTime!)}
                                  />
                              }
                              label="All-day event"
                          />
                      </Grid>
                  </Grid>
              </div>
          </div>
      );
  };

    private onChangeEventEndTime = (event: Date, startTime: string) =>
        this.props.onChange({ ...this.props.eventTimes, [startTime]: event.toISOString() });

    private onChangeEventStartTime = (startTime: Date, startDate: string) =>
        this.setNewEventStartTime(startDate, startTime.toISOString(), this.props.eventTimes![startDate]);

    private onChangeEventTime = (date, startTime: string | null) => {
        if (!startTime) this.onHideNewEventTime();

        const eventStartTime: string = !!startTime ? moment(date).toISOString() : moment(date).startOf("hour").add(1, "hour").toISOString();
        const eventEndTime: string | null = !!startTime
            ? !!this.props.eventTimes![startTime]
                ? moment(this.props.eventTimes![startTime]!)
                      .set("dayOfYear", moment(date).get("dayOfYear"))
                      .set("year", moment(date).get("year"))
                      .toISOString()
                : null
            : moment(eventStartTime).add(30, "minutes").toISOString();

        this.setNewEventStartTime(startTime, eventStartTime, eventEndTime);
    };

    private onHideNewEventTime = () => {
        this.setState({ showNewEventTime: false });
    };

    private onToggleAllDay = (event, checked: boolean, startTime: string) => {
        const eventStartTime: string = moment(startTime).startOf("day").toISOString();
        const eventEndTime: string | null = checked ? null : moment(eventStartTime).add(30, "minutes").toISOString();

        this.setNewEventStartTime(startTime, eventStartTime, eventEndTime);
    };

    private setNewEventStartTime = (prevStartTime: string | null, nextStartTime: string, endTime: string | null) => {
        let eventTimes: IEventTimes = {};

        if (!!prevStartTime) {
            Object.keys(this.props.eventTimes!).map((eventTime) => {
                if (eventTime === prevStartTime) eventTimes[nextStartTime] = endTime;
                else eventTimes[eventTime] = this.props.eventTimes![eventTime];

                return eventTime;
            });
        } else {
            eventTimes = { ...this.props.eventTimes, [nextStartTime]: endTime };
        }

        this.props.onChange(eventTimes);
    };
}


    interface ComponentProps {
    eventTimes: IEventTimes | undefined;
    onChange: (eventTimes: IEventTimes) => void;
    }

    interface ComponentState {
    }

    export default EventTimes;